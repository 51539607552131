.form-check-input[type=radio] {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: .125em;
  margin-left: -1.5rem;
}
.form-check-input{
  margin: 0 auto;
}
.form-check-input[type=radio]:before {
  width: 1rem;
  height: 1rem
}

.form-check-input[type=radio]:after {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  z-index: 1;
  display: block;
  border-radius: 50%;
  background-color: #fff
}

.form-check-input[type=radio]:checked {
  background-image: none;
  background-color: #fff
}

.form-check-input[type=radio]:checked:after {
  border-radius: 50%;
  width: .625rem;
  height: .625rem;
  border-color: #3b71ca;
  background-color: #3b71ca;
  transition: border-color;
  transform: translate(-50%,-50%);
  position: absolute;
  left: 50%;
  top: 50%
}

.form-check-input[type=radio]:checked:focus {
  background-color: #fff
}

.form-check-label {
  padding-left: .15rem
}

.form-check-label:hover {
  cursor: pointer
}
.justify-content-center {
  justify-content: center!important;
}
.card-flex{
  display: flex;
  margin: 0 auto;
 
}
.card-bod{
  padding: left 3;
  margin: 3;
}
.RadioButton{
  margin: 2;

}
.but-row{
  margin: initial;
}
.Radiobutongr{
  height: 12rem;
  justify-content: initial;  
}
.react-form-wizard .wizard-tab-content {
  min-height: 100px;
  padding: 10px 20px 10px;
  text-align: start;
}
.titulo-encuesta{
  text-align: center;
}
.encuesta-div{
  height: 20rem;
}