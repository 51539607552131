.card.tit{
    background-color: darkorange;
}

.react-form-wizard .wizard-nav>li:not(.active)  {
    display:none !important;
}
.container, .container-fluid{
    padding-left: 0px;
    padding-right: 0px;
}
.react-form-wizard, .totals{
    background-color: white;
    border-radius: 5px;
}

.btn-group>.btn-check:checked+.btn, .btn-group-vertical>.btn-check:checked+.btn{
    background-color: grey;
    color: white;
    font-weight: bold;
}
.btn-outline-secondary:hover:not(.active) {
    background-color: grey;
    color: white;
    font-weight: bold;
}
.form-label{
    font-weight:bold;
    font-size: 14px;
}
.react-form-wizard .wizard-title{
    background: linear-gradient(90deg, #7b809a 0%, rgb(53 46 48) 50%, rgb(38 162 178) 100%);
    padding: 20px 20px;
    border-radius: 5px;
    color: white !important;
}
.react-form-wizard .wizard-btn, .react-form-wizard .navbar .navbar-nav>li>a.wizard-btn {
    box-sizing: border-box;
    border-width: 2px;
    background-color: #e91e63;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    min-width: 140px;
    color: white;
}
.inputPresion{
    border: 1px #ccc solid;
}